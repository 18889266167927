<template>
    <div id="app">
        <debtor-warning-modal v-if="$store.getters.USER_INFO && $store.getters.USER_INFO.parentId === null"></debtor-warning-modal>
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
import loginLayout from '@/layouts/loginLayout'
import mainLayout from '@/layouts/mainLayout'
import profileLayout from '@/layouts/profileLayout'
import customLayout from '@/layouts/customLayout'
import {mapActions} from "vuex";
import DebtorWarningModal from "@/components/app/modals/debtor/DebtorWarningModal.vue";

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || 'main-layout')
        }
    },
    methods: {
        ...mapActions([
            'checkIsMobile'
        ])
    },
    components: {
        DebtorWarningModal,
        loginLayout, mainLayout, profileLayout, customLayout
    },
    created: function () {
        window.addEventListener("resize", this.checkIsMobile);
        this.checkIsMobile();

        this.$http.interceptors.response.use(undefined, function (err) {
            return new Promise(function (resolve, reject) {
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                    this.$store.dispatch("LOGOUT")
                }
                throw err;
            });
        });
    }
}
</script>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'assets/sass/app/app.scss';

</style>
