<script>
import ModalGetAllPaymentWays from "@/components/app/modals/debtor/GetAllPaymentWays";
import displaySettings from "@/mixin/displaySettings";
import VueCookie from 'vue-cookie';

export default {
    name: 'DebtorWarningModal',
    components: {ModalGetAllPaymentWays},
    mixins: [displaySettings],
    data() {
        return {
            dialogVisible: false,
            testMode: process.env.VUE_APP_SERVER_MODE === 'test',
            checkDays: [2,5],
            needCheck: true,
        };
    },
    computed: {
        amountOwed() {
            return Math.abs(this.$store.getters.ALL_TRANSACTIONS.amount)
        }
    },
    methods: {
        openPayModal() {
            this.dialogVisible = false;
            this.$refs.modalGetAllPaymentWays.dialogVisible = true;
        },
        handleClose(done) {
            this.$confirm('Are you sure to close this dialog?')
                .then(_ => {
                    done();
                })
                .catch(_ => {
                });
        },
        payLater() {
            axios('/api/debtor/pay-later')
            this.dialogVisible = false;
            this.needCheck = true;
        },
        checkDebtor() {
            //console.log(this.checkDays.includes(moment().day()))
            if (this.checkDays.includes(moment().day()) && moment(VueCookie.get("click_pay_later")).day() !== moment().day() && this.$store.getters.ALL_TRANSACTIONS.amount < -100 && this.needCheck) {
                this.dialogVisible = true
                this.needCheck = false;
            }
        },
        testDebtor() {
            this.$alert('ТЕСТ Перевірка дебіторки буде сьогодні(період 10сек). Наявність cookie "click_pay_later" це означає, що було натиснуто сплачу піздніше')
            this.checkDays.push(moment().day())
        }
    },
    mounted() {
        // this.checkDebtor()
        setInterval(() => {
            this.checkDebtor()
        }, 10000)
    }
};
</script>

<template>
    <div>
        <div v-if="testMode" class="text-center">
            <el-button @click="testDebtor">Тест дебіторки</el-button>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :show-close="false"
            :before-close="handleClose"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :width="isMobile ? '100%' : '30%'"
            center
        >
            <div class="debtor-content">
                <div class="mb-4">
                    <span>Шановний партнере!</span>
                </div>
                <div class="mb-4">
                    <span>Звертаємо вашу увагу, що сьогодні необхідно здійснити оплату за поліси по балансу.</span>
                    <br>
                    <span>Загальна сума до сплати: {{ amountOwed }} грн.</span>
                </div>
                <div class="mb-4">
                    Будь ласка, оберіть зручний спосіб оплати або повідомте, якщо оплата буде відтермінована.
                </div>

                <div class="mb-3">
                    <el-button class="w-100" type="primary" @click="openPayModal">Варіанти оплати</el-button>
                </div>
                <div>
                    <el-button class="w-100" @click="payLater">Сплачу пізніше</el-button>
                </div>
            </div>
        </el-dialog>
        <modal-get-all-payment-ways
            ref="modalGetAllPaymentWays"
            :is-legal-type="false"
            @close="dialogVisible = true"
        >
        </modal-get-all-payment-ways>
        <!--    @showModalGetInvoiceOrder="getPaymentInvoice"-->
        <!--    @showModalGetInstantLoan="getInstantLoan"-->
        <!--    @showModalGetMonoPayParts="showModalGetMonoPayParts"-->
        <!--    @showModalGetInvoiceLegal="showModalGetInvoiceLegal"-->
    </div>
</template>

<style scoped>
.debtor-content {
    margin: 1rem 2rem 1rem 2rem;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #24282c;
    word-break: break-word;

    span {
        font-weight: 700;
    }

    .el-button {
        font-size: 12px;
    }
}
</style>
