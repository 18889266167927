const state = {
    types: [1011,1010,1012,1023],
};

const getters = {
    EXCLUDE_MODULE: state => state.types
};

export default {
    state,
    getters
};
