export default {
    data() {
        return {

        };
    },
    computed: {
        availableDocuments() {
            if(this.offerInfo){
                return this.offerInfo?.available_documents ?? this.offerInfo?.allowed_documents ?? null;
            }else if(this.orderData){
                return this.orderData.offer?.available_documents ?? this.orderData.offer?.allowed_documents ?? null;
            }
        },
        allDocumentTypes () {
            return this.$store.getters.DOCUMENTS_TYPES
        },
    },
    mounted() {

    },
    /**
     *
     * @returns {*}
     */
    serverPrefetch () {
        this.fetchPartnersList();
    },
    methods: {
        getAllowedDocumentsTypes(documents) {
            let transformedDocs = this.availableDocuments.map(docId => {
                let matchingItem = this.allDocumentTypes.find(item => item.id === docId);
                if(!matchingItem) return false;
                return {
                    id: matchingItem.id,
                    name: matchingItem.name,
                    uid: matchingItem.uid,
                };
            });
            const validUids = transformedDocs.map(doc => parseInt(doc.uid));
            const filteredDocumentOptions = documents.filter(doc => validUids.includes(parseInt(doc.document_type)));
            return filteredDocumentOptions;
        },
        getUidOfDocument(documentWord) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: documentWord })?.uid;
        },
        getDocumentIdByUid(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { uid: parseInt(documentUid) })?.id;
        },
        getDocumentUidById(documentIdWord) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: documentIdWord })?.uid;
        },
        getDocumentUidByUid(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { uid: parseInt(documentUid) })?.uid;
        },
        // getDocumentIdByUid(documentUid) {
        //     return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { uid: parseInt(documentUid) })?.id;
        // },
        getDocumentById(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: documentUid })?.id;
        },
        isDocumentERetireeByUid(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: 'DOCUMENT_E_RETIREE' })?.uid === documentUid;
        },
        isDocumentPassport(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: 'DOCUMENT_PASSPORT' })?.uid === documentUid;
        },
        isDocumentExternalPassport(documentId) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: 'DOCUMENT_EXTERNAL_PASSPORT' })?.id === documentId;
        },
        isDocumentIDPassport(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: 'DOCUMENT_ID_CARD' })?.uid === documentUid;
        },
        isDocumentIdResidencePermit(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: 'DOCUMENT_ID_RESIDENCE_PERMIT' })?.uid === documentUid;
        },
        isDocumentResidencePermit(documentUid) {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: 'DOCUMENT_RESIDENCE_PERMIT' })?.uid === documentUid;
        },
        convertDocumentToNumber(documentValue) {
            return this.getDocumentUidByUid(documentValue) ?? this.getDocumentUidById(documentValue);
        }
        // getUidOfDocument(documentWord) {
        //     return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: documentWord })?.uid;
        // },
    }
}
