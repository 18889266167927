const state = {
    // "no" => 1, // Нет привелегий
    // "e_retired" => 6, // Пенсионеры граждане Украины (электронное удостоверение)
    // "invalid_war" => 9, //Особа з інвалідністю внаслідок війни
    list: [
        {
            // "retired" => 2, // Пенсионеры граждане Украины
            text: "Посвідчення пенсіонера",
            value: 'Пенсіонер',
            name: 'retired',
            id: 2,
            document: 'DOCUMENT_RETIREE',
            documentUid: 7
        }, // Пенсионеры граждане Украины
        {
            // "war" => 3, // Участники войны, которые определены законом
            text: "Посвідчення учасника війни",
            value: 'Учасники війни, що визначені законом',
            name: 'war',
            id: 3,
            document: 'DOCUMENT_VETERAN_CERTIFICATE',
            documentUid: 9
        }, // Участники войны, которые определены законом
        {
            // "maidan" => 7, //Постраждалі учасники Революції Гідності
            text: "Постраждалий учасник революції Гідності",
            value: 'Постраждалий учасник революції Гідності',
            name: 'maidan',
            id: 7,
            document: 'DOCUMENT_MAIDAN_CERTIFICATE',
            documentUid: 16
        },
        {
            // "ubd" => 10, //Учасник бойових дій
            text: "Посвідчення учасника бойових дій",
            value: 'Учасник бойових дій',
            name: 'ubd',
            id: 10,
            document: 'DOCUMENT_UBD_CERTIFICATE',
            documentUid: 17
        },
        {
            // "invalid_i" => 8, //Инвалиды I группы
            text: "Посвідчення інваліда I групи",
            value: 'Інвалід I групи',
            name: 'invalid',
            id: 8,
            // document: 'DOCUMENT_HANDICAPPED',
            document: 'DOCUMENT_HANDICAPPED_I_GROUP',
            documentUid: 8
        }, // Инвалиды I группы
        {
            // "invalid" => 4, // Инвалиды II группы
            text: "Посвідчення інваліда IІ групи",
            value: 'Інвалід II групи',
            name: 'invalid',
            id: 4,
            document: 'DOCUMENT_HANDICAPPED_II_GROUP',
            documentUid: 15
        }, // Инвалиды II группы
        {
            // "chernobyl" => 5, // Лица, пострадавшие вследствие Чернобыльской катастрофы, отнесены к I и II категории
            text: "Посвідчення чорнобильця",
            value: 'Чорнобилець I,II кат',
            name: 'chernobyl',
            id: 5,
            document: 'DOCUMENT_CHERNOBYL_VICTIM',
            documentUid: 5
        }, // Лица, пострадавшие вследствие Чернобыльской катастрофы, отнесены к I и II категории
    ],
};

const getters = {
    CUSTOMER_PRIVILEGE: state => state.list
};

export default {
    state,
    getters
};
